<template>
  <div>
    <div class="login" v-if="mostral">
      <v-container>
        <v-row class="text-center primary--text">
          <v-col cols="12">
            <p class="text-h5" style="font-family: Kelly Slab !important">
              <strong style="font-family: Kelly Slab !important"
                >Aproveite a Oportunidade</strong
              >
              de negociar seu débito.
            </p>

            <p class="text-h5" style="font-family: Kelly Slab !important">
              Informe seu CPF/CNPJ para ver o boleto
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title class="text-center"></v-toolbar-title>

                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="Entre com seu CPF/CNPJ"
                    v-model="login"
                    prepend-icon="mdi-account"
                    type="text"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" block @click="enviar">Ver Oferta</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="d-flex justify-center" v-if="mostrab">
      <v-row>
        <v-col xs="12">
          <p class="text-h3">Aguarde...</p>
          <v-progress-linear indeterminate color="cyan"></v-progress-linear>
          <br />
          <v-progress-linear indeterminate color="cyan"></v-progress-linear>
          <br />
          <v-progress-linear indeterminate color="cyan"></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { api } from "@/conf/api";
import {datahoje} from "@/conf/utilitario";
export default {
  name: "PlanoUnismg2",
  data() {
    return {
      mostral: false,
      mostrab: true,
      cpf: "",
      contrato: "",
      tipo: "",
      login: "",
    };
  },
  async beforeMount() {
    this.$store.dispatch("setMostraBotoes", { imprimir: false, voltar: false });
  },
  async mounted() {
    let divida = this.$store.state.divida_atual;
    //let criterios = this.$store.state.unismg_criterios;
    let valores = this.$store.state.unismg_valores;
   // let faturas = this.$store.state.unismg_faturas;
    
    let negoc_atual = this.$store.state.negoc_atual;
    //let parcelas_faltam = negoc_atual.parcelas +1

    //console.log("divida", divida);
    //console.log("criterios", criterios);
    //console.log("valores", valores);
    //console.log("fat", faturas);
   // console.log("faltam parc", parcelas_faltam);
   // console.log("negoc", negoc_atual);
    
        let cpf = divida.cpcpfcgc
        let contrato = divida.contrato
        let cgc = divida.cpcgc
        let datanegoc = datahoje()
        let parcela = 1

        let totalparcela = negoc_atual.parcelas +1
        let valor = negoc_atual.valor 
        let venc = valores.dataVenc
        try{
           //----------------------------------------------------------------------
           console.log('dadosbol',cpf,
        contrato,
        cgc,
        datanegoc,
        parcela,
        totalparcela,
        valor,
        venc)
      let resposta = await api.post("bradesco/boleto/gerarboletosite", {
        cpf,
        contrato,
        cgc,
        datanegoc,
        parcela,
        totalparcela,
        valor,
        venc,
      });
      
      console.log("resp", resposta.data);

      if (!resposta.data.dadosboleto) throw new Error("internoerro");
      let meuboleto = resposta.data;
      meuboleto.valores = valores // aqui guardo saldo, valor, desc1,desc2 e atraso
      meuboleto.pacote = 'Unismg'

      
      let minhasFaturas = meuboleto.dadosnegoc.faturas;
      let faturasboleto = [];
      minhasFaturas.forEach((e) => {
        let numdoc = e.pdnumdoc;
        let venc = e.pddtvencto.substring(0, 10);
        let valor = e.pdvalor;
        faturasboleto.push({ numdoc, venc, valor });
      });

      let boleto = {
        cgc: '31404878000116',
        databoleto: meuboleto.dadosnegoc.datanegoc.substring(0, 10),
        nome: meuboleto.dadoscli.nome.trim(),
        cpf: cpf,
        contrato: contrato,
        valor: valor,
        venc: venc,
        faturas: faturasboleto,
        cb: meuboleto.dadosboleto.codBarras.trim(),
        ld: meuboleto.dadosboleto.linhaDig.trim(),
        nossonum: meuboleto.dadosboleto.nossonumComDig.trim(),
        nossonum2: meuboleto.dadosboleto.nossonumero.trim(),
        endereco: meuboleto.dadoscli.end.end.trim(),
        parc: parcela,
        totalparc: totalparcela,
        boleto_ori: meuboleto,
      };
      //-----------------------------------------------------------------------------
      let boletooper = {
        cgc: '31404878000116',
        cpf ,
        contrato,
        boleto : meuboleto,
        valor,
        venc ,
        dataquebrada : meuboleto.dadosnegoc.dataquebrada,
        datanegoc : datanegoc,
        datarem : meuboleto.dadosnegoc.datarem,
        parcela ,
        totalparcela ,
        codBarras : meuboleto.dadosboleto.codBarras,
        linhaDig : meuboleto.dadosboleto.linhaDig,
        nossonumero : meuboleto.dadosboleto.nossonumero,
        nossonumComDig : meuboleto.dadosboleto.nossonumComDig,
        stat: 0
      }
      
      let resp2 = await api.post("bradesco/boleto/insereoper", boletooper);
     console.log('oper',resp2)

     //-----------------------------------------------------------------------------
     let dados = {
      credor: 'UNISMG',
      cpf,
      contrato,
      valor,
      venc,
      fatura: null,
      codBarras: meuboleto.dadosboleto.codBarras,
      L_dig: meuboleto.dadosboleto.linhaDig,
      remessa: null,
      parcelas_faltam: null,
      email: '',
      valor_entrada: null,
      valor_parcela: null,
    };
    console.log('gravar',dados)
   await api.post(`logs/gravaacordosomente`, dados);
     
      //-----------------------------------------------------------------------------

      // console.log('boletoviasat',boleto)

      this.$store.dispatch("insereBolViasat", boleto);
      console.log('boleto',this.$store.state.boleto_viasat)
      this.$router.push({ name: "BolUnismg2" });
           //----------------------------------------------------------------------
        }catch (e) {
      console.log("err", e.message);
      this.$router.push({ name: "ErroPage" });
    }
     
  },
  methods: {
    async enviar() {
      this.$store.commit("zeraMessage");
      let meu = this.login.trim();
      if (this.cpf != meu) {
        this.$store.commit("showMessage", {
          text: "CPF/CNPJ divergente!",
          color: "warning",
          show: true,
        });
        this.login = "";
        return;
      }
      this.mostral = false;
      this.mostrab = true;
      await api.post("Logs/inserecpflog", { cpf: this.cpf });
      let datat = {
        cpf: this.cpf,
        contrato: this.contrato,
        tipo: this.tipo,
      };
      try {
        let resposta = await api.post("bradesco/boleto/pegarboleto2", datat);
        console.log("resp", resposta.data);
        if (!resposta.data[0].boleto) throw new Error("internoerro");
        let meuboleto = resposta.data[0];

        let minhasFaturas = meuboleto.boleto.dadosnegoc.faturas;
        let faturasboleto = [];
        minhasFaturas.forEach((e) => {
          let numdoc = e.pnnumdoc;
          let venc = e.pndtvencto.substring(0, 10);
          let valor = e.pnvalor;
          faturasboleto.push({ numdoc, venc, valor });
        });

        let boleto = {
          databoleto: meuboleto.datanegoc.substring(0, 10),
          nome: meuboleto.boleto.dadoscli.nome.trim(),
          cpf: meuboleto.cpf.trim(),
          contrato: meuboleto.contrato.trim(),
          valor: meuboleto.valor,
          venc: meuboleto.venc.substring(0, 10),
          faturas: faturasboleto,
          cb: meuboleto.codbarras.trim(),
          ld: meuboleto.linhadig.trim(),
          nossonum: meuboleto.nossonumcomdig.trim(),
          nossonum2: meuboleto.nossonumero.trim(),
          endereco: meuboleto.boleto.dadoscli.end.end,
          parc: meuboleto.parcela,
          totalparc: meuboleto.totalparcela,
          boleto_ori: meuboleto,
        };

        //console.log(boleto)

        this.$store.dispatch("insereBolViasat", boleto);
        this.$router.push({ name: "BolUnismg2" });
      } catch (e) {
        console.log("err", e.message);
        this.$router.push({ name: "ErroPage" });
      }
    },
  },
};
</script>
